.ap-button {
    font-family: font-8-bit;
    font-size: min(2vw, 2vh);
    color: white;
    text-align: center;
    background: #c10000;
    box-shadow: none;
    border: 3px solid white;
    padding: 5px 50px;

    display:inherit;
    margin: auto;

    margin-top: 20px;
}

.ap-button:hover {
    background: #e10000;
}

.ap-button:disabled {
    border: 3px solid gray;
    color: gray;
}

.ap-text {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.ap-error {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: red;
}
