.alc-big_space {
    height: 50px;
}

.alc-button {
    font-family: font-8-bit;
    font-size: min(2vw, 2vh);
    color: white;
    text-align: center;
    background: #c10000;
    box-shadow: none;
    border: 3px solid white;
    padding: 5px 50px;

    display:inherit;
    margin: auto;

    margin-top: 20px;
}

.alc-button:hover {
    background: #e10000;
}

.alc-button:disabled {
    border: 3px solid gray;
    color: gray;
}

.alc-text {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.alc-error {
    margin-bottom: 10px;
    font-size: min(1.5vw, 1.5vh);
    text-align: center;
    color: red;
}

.alc-input {
    font-size: min(4vw, 4vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 10px;
    color: #0c2f4e;
    border: #0c2f4e solid 2px;
}

.alc-input-error {
    font-size: min(4vw, 4vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 10px;
    color: red;
    border: #0c2f4e solid 2px;
}