.ap-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.ap-account {
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
}

.ap-account-button {
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
    background: none;
    box-shadow: none;
    border-radius: 0px;
}