html {
    background-color: #1e1e1e;
}

.mp-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.mp-page {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.mp-container {
    height: 100%;
    position: relative;
}

.mp-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    object-fit: contain;
}

.mp-main_image {
    position: absolute;
    -ms-transform: translate(5%, -53%);
    transform: translate(5%, -53%);
    width: 140%;
    pointer-events: none;
    user-select: none;
}

.mp-logo {
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: min(14vw, 10vh);
    color: rgb(255, 255, 255);
    text-align: center;
    pointer-events: none;
    user-select: none;
}

.mp-mail {
    font-size: min(4vw, 3vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 255);
    margin-top: 35%;
    text-align: center;
}

.mp-mission {
    font-size: min(3vw, 2vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    text-align: center;
    pointer-events: none;
    user-select: none;
}

.mp-link {
    color: rgb(255, 255, 255);
}