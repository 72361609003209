.mm-root {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 40px;
    color: white;
    font-size: 10px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.mm-button {
    color: white;
    font-size: 10px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: none;
    box-shadow: none;
    border-radius: 0px;
}