.vc-root {
    position: fixed;
    left: 0px;
    top: 0px;
    border: 2px dotted white;
    color: white;
    background-color: #1e1e1e;
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    width: 200px;
    max-width: 200px;
    text-shadow: none;
}

.vc-title {
    font-size: 18px;
}

.vc-name {
    font-size: 16px;
}

.vc-join-button {
    color: white;
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 3px;
}

.vc-mute-button {
    color: white;
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 3px;
}

.vc-settings-button {
    color: white;
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 3px;
}