.trc-title-text {
    font-size: min(3vw, 3vh);
    margin-top: 30px;
    text-align: center;
}

.trc-room-text {
    font-size: min(2.5vw, 2.5vh);
    margin-top: 20px;
    text-align: center;
    text-decoration: underline;
    text-shadow: none;
}

.trc-player-line {
    margin-top: 10px;
    text-align: center;
}

.trc-player-text {
    font-size: min(2vw, 2vh);
}

.trc-scroll {
    display: block;
    overflow-y: auto;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.trc-account-icon {
    height: 24px;
    margin-right: 10px;
    border: white solid 2px;
    vertical-align:middle
}

.trc-button {
    color: white;
    font-size: min(1.5vw, 1.5vh);
    font-family: font-8-bit;
    margin: auto;
    display: block;
    text-align: center;
    background: #3b0786;
    box-shadow: none;
    border: 3px solid white;
    text-decoration: none;
}

.trc-button-text {
    margin: 5px 10px;
}