.tco-root {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 300px;
    border: 2px dotted white;
    max-width: 250px;
    color: white;
    background-color: #1e1e1ee0;
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: none;
}

.tco-title {
    font-size: 18px;
}

.tco-unread {
    color: red;
}

.tco-opened {
    bottom: 0px;
}

.tco-button {
    color: white;
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 3px;
}

.tco-messages-block {
    left: 0px;
    right: 0px;
    height: calc(100% - 65px);
    overflow: scroll;
    border: 1px solid lightgray;
    margin: 3px;
}

.tco-message {
    background: rgba(211, 211, 211, 0.45);
    color: black;
    text-align: left;
    margin: 5px;
}

.tco-own-message {
    background: rgba(128, 128, 128, 0.45);
    color: white;
    text-align: right;
    margin: 5px;
}

.tco-message-sender {
    font-size: 12px;
    text-decoration: underline;
    margin: 2px;
}

.tco-message-text {
    margin: 5px;
    white-space: pre-wrap;
    font-size: 14px;
    overflow-wrap: break-word;
}

.tco-message-block {
    left: 0px;
    right: 0px;
}

.tco-message-input {
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    width: 70%;
    border: 2px solid gray;
    background: none;
    color: white;
    margin: 3px;
    vertical-align: middle;
    resize: none;
}

.tco-send-button {
    color: white;
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    width: 20%;
    height: 39px;
    margin: 3px;
    vertical-align: middle;
}

.tco-container {
    bottom: 0px;
    height: calc(100% - 105px);
}

.tco-emoji {
    display: inline-block;
    font-size: 18px;
    border: none;
    background: none;
}