.oap-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
}

.oap-title-0 {
    font-size: 60px;
    text-align: center;
    margin: 20px;
}

.oap-title-1 {
    font-size: 40px;
    margin: 10px;
    text-align: center;
}

.oap-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: #000000cc;
}

.oap-divider {
    width: 100%;
    border: 1px solid white;
}

.oap-big-space {
    height: 20px;
}

.oap-dialog {
    margin: 100px auto;
    padding: 20px;
    border: 4px solid white;
    width: 50%;
    background-color: #1f1f1f;
}

.oap-button {
    font-size: 24px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
    border: 2px solid white;
    background: none;

    display:inherit;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.oap-button:hover {
    background: darkgray;
}

.oap-button:disabled {
    border: 2px solid darkgray;
    color: darkgray;
}

.oap-button:disabled:hover {
    background: none;
}

.oap-big-text {
    font-size: 20px;
    text-align: center;
    margin: 5px;
}

.oap-text {
    font-size: 16px;
    text-align: center;
    margin: 5px;
}

.oap-error {
    font-size: min(1.5vw, 1.5vh);
    text-align: center;
    color: red;
}

.oap-input-error,
.oap-input {
    font-size: 24px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    color: white;
    border: white solid 2px;
    background: none;
}

.oap-input-error {
    color: red;
}

.oap-avatars-center {
    margin: auto;
    width: min(80vw, 80vh);
    padding: 10px;
}

.oap-avatars {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.oap-avatar,
.oap-avatar-selected {
    display: block;
    width: min(9.4vw, 9.4vh);
    height: min(9.4vw, 9.4vh);
}

.oap-avatar-selected {
    background: white;
}

.oap-avatar-image {
    position: relative;
    top: min(0.7vw, 0.7vh);
    left: min(0.7vw, 0.7vh);
    width: min(8vw, 8vh);
    height: min(8vw, 8vh);
}

.oap-add-avatar {
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    width: min(9.4vw, 9.4vh);
    height: min(9.4vw, 9.4vh);
    border: 5px solid white;
    background: none;
    color: white;
    text-align: center;
    font-size: min(4vw, 4vh);
}

.oap-add-avatar:hover {
    background: darkgray;
}