.fff-lb-button {
    font-family: font-8-bit;
    font-size: 16px;
    color: white;
    text-align: center;
    background: #3b0786;
    box-shadow: none;
    border: 3px solid white;
    padding: 5px 50px;

    display:inherit;
    margin: auto;
}