.tlp-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
}

.tlp-button {
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 3px;
}

.tlp-title {
    font-size: 20px;
}

.tlp-player {
    font-size: 20px;
    margin: 5px;
}

.tlp-col {
    width: 300px;
    display: inline-block;
}