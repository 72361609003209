.cho-root {
    position: absolute;
    bottom: 1px;
    left: 0px;
    width: 220px;
    max-width: 220px;
    border: 2px dotted white;
    color: white;
    background-color: #1e1e1e;
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: none;
}

.cho-title {
    font-size: 18px;
}

.cho-opened {
    height: 50%;
}

.cho-button {
    color: white;
    font-size: 14px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 3px;
}

.cho-results-block {
    left: 0px;
    right: 0px;
    height: 100%;
    overflow: scroll;
    border: 1px solid lightgray;
    margin: 3px;
}

.cho-results {
    background: gray;
    color: white;
    text-align: left;
    margin: 5px;
}

.cho-player-name {
    font-size: 12px;
    margin-left: 2px;
    margin-top: 2px;
}

.cho-combination {
    margin-left: 5px;
    font-size: 12px;
}

.cho-red-suit {
    color: rgb(255, 56, 56);
}

.cho-black-suit {
    color: rgb(58, 58, 58);
}

.cho-container {
    bottom: 0px;
    height: calc(100% - 70px);
}