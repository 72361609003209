.vcs-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000000C0;
    z-index: 1;
}

.vcs-dialog {
    background-color: #1e1e1e;
    margin: 20px auto;
    padding: 20px;
    border: 2px solid #888;
    width: 50%;
}

.vcs-title {
    font-size: min(4vw, 4vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
    margin-top: 10px;
}

.vcs-text {
    font-size: min(2vw, 2vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
    margin-top: 10px;
}

.vcs-select {
    font-size: min(2vw, 2vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 10px;
}

.vcs-close-button {
    color: white;
    font-size: min(3vw, 3vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: none;
    box-shadow: none;
    border-radius: 0px;

    margin: auto;
    display: block;
    text-align: center;
 
    margin-top: 20px;
}