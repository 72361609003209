.ctp-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;

    background-color: #000000C0;
    z-index: 1;
}

.ctp-dialog {
    background-color: #1e1e1e;
    margin: 20px auto;
    padding: 20px;
    border: 2px solid #888;
    width: 50%;
}

.ctp-time {
    font-size: 24px;
}

.ctp-title {
    font-size: 20px;
}

.ctp-button {
    font-size: 24px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
    background: none;
    box-shadow: none;
    border-radius: 0px;

    margin: 3px;
}

.ctp-datetime {
    font-size: 20px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.2;
}

.ctp-input {
    font-size: 20px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    display: block;
}

.ctp-select {
    font-size: 20px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    display: block;    
}