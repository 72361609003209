.tc-blue-status-text {
    font-size: min(2vw, 2vh);
    text-align: center;
    margin: auto;
    display: block;
    margin-top: 50px;
}

.tc-blue-date-text {
    font-size: min(3vw, 3vh);
    text-align: center;
    margin: auto;
    display: block;
    margin-top: 50px;
}

.tc-blue-button {
    color: white;
    font-size: min(2vw, 2vh);
    font-family: font-8-bit;
    margin: auto;
    display: block;
    text-align: center;
    background: #c10000;
    box-shadow: none;
    border: 3px solid white;

    margin-top: 50px;
}

.tc-blue-button-text {
    margin: 10px 20px;
}