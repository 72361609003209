.ttp-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
}

.ttp-head-title {
    font-size: 28px;
}

.ttp-input-title {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 10px;
}

.ttp-input {
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    display: block;
}

.ttp-select {
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    display: block;    
}

.ttp-button {
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 3px;
}