.oilp-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #1e1e1e;
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
}

.oilp-vertical-center {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: grid;
}

.oilp-logo {
    font-size: min(10vw, 10vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
}

.oilp-game {
    font-size: min(5vw, 5vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
}

.oilp-big_space {
    height: 50px;
}

.oilp-small_space {
    height: 30px;
}

.oilp-text {
    font-size: min(6vw, 6vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
}

.oilp-small-text {
    font-size: min(4vw, 4vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
}

.oilp-user_input {
    font-size: min(5vw, 5vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    color: #1c1c1c;
}

.oilp-join_button {
    color: white;
    font-size: min(4vw, 4vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    background: purple;
    box-shadow: none;
    border-radius: 0px;
    width: 200px;
}

.oilp-join_button:disabled {
    background: #1c1c1c;
    color: #999
}

.oilp-avatars-center {
    margin: auto;
    width: min(80vw, 80vh);
    padding: 10px;
}

.oilp-avatars {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.oilp-avatar,
.oilp-avatar-selected {
    display: block;
    width: min(9.4vw, 9.4vh);
    height: min(9.4vw, 9.4vh);
}

.oilp-avatar-selected {
    background-color: purple;
}

.oilp-avatar-image {
    position: relative;
    top: min(0.7vw, 0.7vh);
    left: min(0.7vw, 0.7vh);
    width: min(8vw, 8vh);
    height: min(8vw, 8vh);
}