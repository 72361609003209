.apd-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000000C0;
    z-index: 1;
}

.apd-dialog {
    background-image: url(../resources/background_blue.png);
    background-repeat: repeat;    
    margin: 20px auto;
    padding: 20px;
    border: 4px solid white;
    width: 50%;
}

.apd-title {
    font-family: font-8-bit;
    font-size: min(3vw, 3vh);
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
    margin-top: 10px;
}

.apd-big_space {
    height: 50px;
}

.apd-small_space {
    height: 25px;
}

.apd-input-error, 
.apd-input {
    font-size: min(3vw, 3vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 10px;
    color: #0c2f4e;
    border: #0c2f4e solid 2px;
    width: 95%;
}

.apd-input-error {
    color: red;
}

.apd-button {
    font-family: font-8-bit;
    font-size: min(2vw, 2vh);
    color: white;
    text-align: center;
    background: #c10000;
    box-shadow: none;
    border: 3px solid white;
    padding: 5px 50px;

    display:inherit;
    margin: auto;

    margin-top: 20px;
}

.apd-button:hover {
    background: #e10000;
}

.apd-button:disabled {
    border: 3px solid gray;
    color: gray;
}

.apd-text {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.apd-avatars-center {
    margin: auto;
    width: min(80vw, 80vh);
    padding: 10px;
}

.apd-avatars {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.apd-avatar,
.apd-avatar-selected {
    display: block;
    width: min(9.4vw, 9.4vh);
    height: min(9.4vw, 9.4vh);
}

.apd-avatar-selected {
    background: #b12418;
}

.apd-avatar-image {
    position: relative;
    top: min(0.7vw, 0.7vh);
    left: min(0.7vw, 0.7vh);
    width: min(8vw, 8vh);
    height: min(8vw, 8vh);
}
