.ssc-state-default {
    text-shadow: none;
    background-color: darkgrey;
}

.ssc-state-lost {
    text-shadow: none;
    background-color: darkred;
}

.ssc-state-won {
    text-shadow: none;
    background-color: darkgreen;
}

.ssc-state-booked {
    text-shadow: none;
    background-color: darkgoldenrod;
}

.ssc-state-left {
    text-shadow: none;
    background-color: darkred;
}

.ssc-state-skipped {
    text-shadow: none;
    background-color: darkred;
}