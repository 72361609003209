.tic-root {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.tic-root > iframe {
    width: 100%;
    height: 100%;
}