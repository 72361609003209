.tp-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
}

.tp-address {
    font-size: 20px;
}

.tp-time {
    font-size: 24px;
}

.tp-title {
    font-size: 20px;
}

.tp-tournament {
    margin: 10px;
    font-size: 16px;
}

.tp-subscription {
    margin: 10px;
    font-size: 16px;
    color: gray;
}

.tp-own-subscription {
    margin: 10px;
    font-size: 16px;
    color: white;
}

.tp-button {
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 3px;
}

.tp-datetime {
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.2;
}

.tp-link {
    color: white;
}