.p2ptp-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: rgb(255, 255, 255);
}