.dlp-root {
    color: white;
    font-size: 10px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
}

.dlp-title {
    font-size: 20px;
}

.dlp-text {
    font-size: 16px;
}

.dlp-account {
    font-size: 16px;
    margin: 3px;
}

.dlp-input {
    font-size: 20px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    display: block;
    width: 500px;
}

.dlp-button {
    font-size: 16px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 3px;
}

.dlp-avatars {
    display: inline-block;
}

.dlp-avatar,
.dlp-avatar-selected {
    display: inline-block;
    width: min(9.4vw, 9.4vh);
    height: min(9.4vw, 9.4vh);
}

.dlp-avatar-selected {
    background-color: green;
}

.dlp-avatar-image {
    position: relative;
    top: min(0.7vw, 0.7vh);
    left: min(0.7vw, 0.7vh);
    width: min(8vw, 8vh);
    height: min(8vw, 8vh);
}

.dlp-account-icon {
    height: 32px;
    margin: 3px;
    vertical-align: middle;
    border: white solid 2px;
}