.mml-button {
    font-size: 26px;
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    text-align: center;
    background: purple;
    box-shadow: none;
    border-radius: 0px;
    width: 400px;
    margin: auto;
}