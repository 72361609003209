@font-face {
    font-family: 'font-8-bit';
    src: url(../resources/8-bit-hud.ttf) format('truetype');
}

.tp-blue-background,
.tp-blue-content,
.tp-blue-root {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    font-family: font-8-bit;
    color: white;

    text-shadow:
       3px 3px 0 #0c2f4e,
     -1px -1px 0 #0c2f4e,  
      1px -1px 0 #0c2f4e,
      -1px 1px 0 #0c2f4e,
       1px 1px 0 #0c2f4e;
}

.tp-blue-background {
    position: fixed;
    background-image: url(../resources/background_blue.png);
    background-repeat: repeat;    
    overflow: hidden;
}

.tp-blue-card-0,
.tp-blue-card-1,
.tp-blue-card-2,
.tp-blue-card-3,
.tp-blue-card-4 {
    position: absolute;
    background-image: url(../resources/card.png);
    width: 116px;
    height: 156px;
}

.tp-blue-card-0 {
    transform: rotate(-80deg) scale(150%);
    top: 300px;
    right: -20px;
}

.tp-blue-card-1 {
    transform: rotate(-60deg) scale(150%);
    top: 200px;
    right: -20px;
}

.tp-blue-card-2 {
    transform: rotate(-40deg) scale(150%);
    top: 100px;
    right: -20px;
}

.tp-blue-card-3 {
    transform: rotate(-20deg) scale(150%);
    bottom: 0px;
    left: 140px;
}

.tp-blue-card-4 {
    transform: rotate(10deg) scale(150%);
    bottom: -40px;
    left: 200px;
}


.tp-blue-header {
    margin-top: 35px;
    font-size: 30px;
    text-align: center;
}

.tp-blue-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    margin-bottom: 30px;
}

.tp-blue-game-text {
    margin-top: 30px;
    font-size: 22px;
    text-align: center;
    
}

.tp-blue-login {
    margin: auto;
    display: block;
    margin-top: 150px;
}