.tpc-account {
    text-align: center;
    margin: auto;
    display: block;
    margin-top: 20px;
}

.tpc-account-icon {
    height: 64px;
    margin: 3px;
    vertical-align: middle;
    border: white solid 4px;
}

.tpc-account-name {
    font-size: 20px;
    margin-left: 15px;
}

.tpc-button {
    font-family: font-8-bit;
    font-size: min(1.5vw, 1.5vh);
    color: white;
    text-align: center;
    background: #c10000;
    box-shadow: none;
    border: 3px solid white;
    padding: 5px 50px;

    display:inherit;
    margin: auto;

    margin-top: 20px;
}

.tpc-button:hover {
    background: #e10000;
}

.tpc-button:disabled {
    border: 3px solid gray;
    color: gray;
}