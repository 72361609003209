.root {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
}

.logo {
    font-size: min(10vw, 10vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
}

.game {
    font-size: min(5vw, 5vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.2;
    text-align: center;
}

.big_space {
    height: 50px;
}

.small_space {
    height: 30px;
}

.text {
    font-size: min(6vw, 6vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
}

.small-text {
    font-size: min(4vw, 4vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
}

.user_input {
    font-size: min(5vw, 5vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
}

.join_button {
    color: white;
    font-size: min(4vw, 4vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    background: none;
    box-shadow: none;
    border-radius: 0px;
}

.avatars-center {
    margin: auto;
    width: min(80vw, 80vh);
    padding: 10px;
}

.avatars {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.avatar,
.avatar-selected {
    display: block;
    width: min(9.4vw, 9.4vh);
    height: min(9.4vw, 9.4vh);
}

.avatar-selected {
    background-color: green;
}

.avatar-image {
    position: relative;
    top: min(0.7vw, 0.7vh);
    left: min(0.7vw, 0.7vh);
    width: min(8vw, 8vh);
    height: min(8vw, 8vh);
}