.asc-big_space {
    height: 50px;
}

.asc-small_space {
    height: 10px;
}

.asc-button {
    font-family: font-8-bit;
    font-size: min(2vw, 2vh);
    color: white;
    text-align: center;
    background: #c10000;
    box-shadow: none;
    border: 3px solid white;
    padding: 5px 50px;

    display:inherit;
    margin: auto;

    margin-top: 20px;
}

.asc-button:hover {
    background: #e10000;
}

.asc-button:disabled {
    border: 3px solid gray;
    color: gray;
}

.acs-error {
    font-size: min(1.5vw, 1.5vh);
    text-align: center;
    color: red;
}

.asc-text {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    font-size: min(1.5vw, 1.5vh);
}

.asc-small-text {
    font-size: min(1vw, 1vh);
    color: rgb(255, 255, 255);
    text-align: center;
}

.asc-input {
    font-size: min(2vw, 2vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 10px;
    color: #0c2f4e;
    border: #0c2f4e solid 2px;
}

.asc-input-error {
    font-size: min(2vw, 2vh);
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 10px;
    color: red;
    border: #0c2f4e solid 2px;
}

.asc-avatars-center {
    margin: auto;
    width: min(80vw, 80vh);
    padding: 10px;
}

.asc-avatars {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.asc-avatar,
.asc-avatar-selected {
    display: block;
    width: min(9.4vw, 9.4vh);
    height: min(9.4vw, 9.4vh);
}

.asc-avatar-selected {
    background: #b12418;
}

.asc-avatar-image {
    position: relative;
    top: min(0.7vw, 0.7vh);
    left: min(0.7vw, 0.7vh);
    width: min(8vw, 8vh);
    height: min(8vw, 8vh);
}

.asc-add-avatar {
    font-family: lores-9-narrow, sans-serif;
    font-weight: 400;
    font-style: normal;
    width: min(9.4vw, 9.4vh);
    height: min(9.4vw, 9.4vh);
    border: 5px solid white;
    background: #c10000;
    color: white;
    text-align: center;
    font-size: min(4vw, 4vh);
}