@font-face {
    font-family: 'font-8-bit';
    src: url(./resources/8-bit-hud.ttf) format('truetype');
}

.fff-lp-background,
.fff-lp-content,
.fff-lp-root {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    font-family: font-8-bit;
    color: white;
}

.fff-lp-background {
    background-image: url(./resources/background.png);
    background-repeat: repeat;    
    overflow: hidden;
}

.fff-lp-card-0,
.fff-lp-card-1,
.fff-lp-card-2,
.fff-lp-card-3,
.fff-lp-card-4 {
    position: absolute;
    background-image: url(./resources/card.png);
    width: 116px;
    height: 156px;
}

.fff-lp-card-0 {
    transform: rotate(-80deg) scale(150%);
    top: 300px;
    right: -20px;
}

.fff-lp-card-1 {
    transform: rotate(-60deg) scale(150%);
    top: 200px;
    right: -20px;
}

.fff-lp-card-2 {
    transform: rotate(-40deg) scale(150%);
    top: 100px;
    right: -20px;
}

.fff-lp-card-3 {
    transform: rotate(-20deg) scale(150%);
    bottom: 0px;
    left: 140px;
}

.fff-lp-card-4 {
    transform: rotate(10deg) scale(150%);
    bottom: -40px;
    left: 200px;
}


.fff-lp-header {
    margin-top: 35px;
    font-size: 30px;
    text-align: center;
}

.fff-lp-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    margin-bottom: 30px;
}

.fff-game-text {
    margin-top: 30px;
    font-size: 22px;
    text-align: center;
    
}

.fff-lp-login {
    margin: auto;
    display: block;
    margin-top: 150px;
}